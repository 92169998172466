/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getReport = /* GraphQL */ `
  query GetReport($id: String!) {
    getReport(id: $id) {
      id
      status
      name
      uri
      queryPath
      type
      fullReport
      custom
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports(
    $id: String
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listReports(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        status
        name
        uri
        queryPath
        type
        fullReport
        custom
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
